import { createStore } from 'vuex'

export default createStore({
  state: {
    questionCategories : [],
    languages : [],
    errors : [],
    success : [],
    loading: false,
    user : null
  },
  getters: {
    getQuestionCategories(state){
      return state.questionCategories;
    },
    getLanguages(state){
      return state.languages;
    },
    getSuccess(state){
      return state.success;
    },
    getErrors(state){
      return state.errors;
    },
    isLoading(state){
      return state.loading;
    },
    getUser(state){
      return state.user;
    }
  },
  mutations: {
    setQuestionCategories(state, payload){
      state.questionCategories = payload;
    },
    setLanguages(state, payload){
      state.languages = payload;
    },
    setError(state,payload){
      state.errors.push(payload);
    },
    setSuccess(state,payload){
      state.success.push(payload);
    },
    removeError(state){
      state.errors.shift();
    },
    removeSuccess(state){
      state.success.shift();
    },
    setLoadingOn(state){
      state.loading = true;
    },
    setLoadingOff(state){
      state.loading = false;
    },
    setUser(state,user){
      state.user = user;
    },
  },
  actions: {
    error({commit} , error){
      commit('setError', error);
      setTimeout(() => {
        commit('removeError');
      },2500)
    },
    success({commit} , error){
      commit('setSuccess', error);
      setTimeout(() => {
        commit('removeSuccess');
      },2500)
    },
  },
  modules: {
  }
})
