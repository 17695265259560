import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path : '',
    name: 'sb-admin',
    component : () => import(/* webpackChunkName: "about" */ '../layout/Auth.vue'),
    children : [
      {
        path : '',
        name : 'dashboard',
        component : () => import(/* webpackChunkName: "about" */ '../views/Rooms.vue'),
      },
      {
        path : '/settings',
        name : 'settings',
        component : () => import(/* webpackChunkName: "about" */ '../views/Settings.vue'),
      },
      {
        path : '/console',
        name : 'console',
        component : () => import(/* webpackChunkName: "about" */ '../views/Console.vue'),
      },
      {
        path : '/games',
        name : 'games',
        component : () => import(/* webpackChunkName: "about" */ '../views/Games.vue'),
      },
      {
        path : '/game/:id',
        name : 'game',
        component : () => import(/* webpackChunkName: "about" */ '../views/Game.vue'),
      },
      {
        path : '/questions',
        name : 'questions',
        component : () => import(/* webpackChunkName: "about" */ '../views/Questions.vue'),
      },
      {
        path : '/question/:id',
        name : 'question',
        component : () => import(/* webpackChunkName: "about" */ '../views/Question.vue'),
      },
      {
        path : '/users',
        name : 'users',
        component : () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
      },
      {
        path : '/user/:id',
        name : 'user',
        component : () => import(/* webpackChunkName: "about" */ '../views/User.vue'),
      },
      {
        path : '/rooms',
        name : 'rooms',
        component : () => import(/* webpackChunkName: "about" */ '../views/Rooms.vue'),
      },
      {
        path : '/room/:kind',
        name : 'room',
        component : () => import(/* webpackChunkName: "about" */ '../views/Room.vue'),
      },
      {
        path : '/categories',
        name : 'categories',
        component : () => import(/* webpackChunkName: "about" */ '../views/Categories.vue'),
      },
    ]
  },
  {
    path: '',
    name: 'annonymous',
    component: () => import(/* webpackChunkName: "about" */ '../layout/Annonymous.vue'),
    children : [
      {
        path : '/login',
        name : 'login',
        component : () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
