import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'video.js/dist/video-js.css';
import '@vuepic/vue-datepicker/dist/main.css';
import "vue-select/dist/vue-select.css";
import 'animate.css';
import '@fortawesome/fontawesome-free/css/all.css';

createApp(App).use(store).use(router).mount('#app')
